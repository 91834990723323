<template>
  <div class="alert">
    <websocket-greetings></websocket-greetings>
  </div>
</template>
<script>
import WebsocketGreetings from "@/components/WebSocketItem.vue";
export default {
  name: "home",
  components: {
    WebsocketGreetings
  }
};
</script>